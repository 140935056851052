import React from 'react'
import { SocialType } from 'domain/enum/socialType'
import styles from './style.module.scss'

interface Props {
  isVertical?: boolean
}

const Socials = (props: Props) => {
  const { isVertical } = props

  const links = [
    {
      title: SocialType.LinkedIn,
      link: 'https://www.linkedin.com/in/clement-baby/',
    },
    { title: SocialType.Github, link: 'https://github.com/clement-baby' },
    { title: SocialType.GitLab, link: 'https://gitlab.com/clement_baby13' },
  ]

  const getSocialIcon = (title: SocialType, isVertical?: boolean) => {
    switch (title) {
      case SocialType.LinkedIn:
        return (
          <img
            className={isVertical ? styles.verticalIconSize : styles.iconSize}
            alt="linkedIn"
            src="/assets/icons/linkedIn.svg"
          />
        )
      case SocialType.Github:
        return (
          <img
            className={isVertical ? styles.verticalIconSize : styles.iconSize}
            alt="github"
            src="/assets/icons/github.svg"
          />
        )
      case SocialType.GitLab:
        return (
          <img
            className={isVertical ? styles.verticalIconSize : styles.iconSize}
            alt="gitlab"
            src="/assets/icons/gitlab.svg"
          />
        )
    }
  }

  return (
    <div
      className={styles.icons}
      style={isVertical ? { flexDirection: 'column' } : {}}
    >
      {links.map((social, index) => (
        <div key={index} onClick={() => window.open(social.link)}>
          {getSocialIcon(social.title, isVertical)}
        </div>
      ))}
    </div>
  )
}

export default Socials
