import { Menu } from 'components/Menu/Menu'
import React from 'react'
import Socials from 'components/Socials/Socials'
import styles from './style.module.scss'

export const Home = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h1 className={styles.h1}>Fullstack Developer</h1>
        <h2 className={styles.h2}>Clément BABY</h2>
        <div
          className={styles.button}
          onClick={() => window.open('mailto:clement.baby9@gmail.com')}
        >
          Me contacter
        </div>
      </div>
      <div className={styles.footerLine}>
        <div className={styles.socials}>
          <Socials />
        </div>
        <div className={styles.menu}>
          <Menu />
        </div>
      </div>
    </div>
  )
}
