export enum IconName {
  Typescript = 'Typescript',
  Javascript = 'Javascript',
  GraphQl = 'GraphQl',
  PostgreSQL = 'PostgreSQL',
  Aws = 'Aws',
  OpenCv = 'OpenCv',
  Tensorflow = 'Tensorflow',
  React = 'React',
  Nodejs = 'Nodejs',
  Vitest = 'Vitest',
  Jest = 'Jest',
  Cplusplus = 'Cplusplus',
  Arduino = 'Arduino',
  Python = 'Python',
  Kicad = 'Kicad',
  Cucumber = 'Cucumber',
  Docker = 'Docker',
  Notion = 'Notion',
  Slack = 'Slack',
  Github = 'Github',
}
