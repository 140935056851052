import '@fontsource/source-sans-pro'
import '@fontsource/source-sans-pro/200.css'
import '@fontsource/source-sans-pro/200-italic.css'
import '@fontsource/source-sans-pro/300.css'
import '@fontsource/source-sans-pro/400.css'
import '@fontsource/source-sans-pro/600.css'
import '@fontsource/source-sans-pro/700.css'

import App from './App'
import React from 'react'
import ReactDOM from 'react-dom/client'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement) // Créez un élément racine (root)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
