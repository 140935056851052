import { useLocation, useNavigate } from 'react-router-dom'

import { PageEnum } from 'domain/enum'
import React from 'react'
import styles from './style.module.scss'

export const Menu = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const handleRedirect = (page: PageEnum) => {
    if (page === PageEnum.Contact) window.open('mailto:clement.baby9@gmail.com')
    else navigate(`/${page}`)
  }

  return (
    <div className={styles.menu}>
      {Object.values(PageEnum).map(page => (
        <div key={page} onClick={() => handleRedirect(page)}>
          {page}
          {location.pathname.includes(page) ? (
            <div className={styles.line}></div>
          ) : (
            <></>
          )}
        </div>
      ))}
    </div>
  )
}
