import React from 'react'
import formations from '../../assets/formation.json'
import styles from './style.module.scss'

const Formation = () => {
  const { data } = formations

  return (
    <>
      {data.map(formation => (
        <div key={formation.id} className={styles.formationContent}>
          <div className={styles.formation}>
            <h3 className={styles.h3}>
              {formation.diploma}
              <div className={styles.line}></div>
            </h3>
            <div className={styles.formationDetail}>
              <div className={styles.formationSchool}>{formation.school}</div>
              <div className={styles.formationPlace}>{formation.place}</div>
            </div>
            <div className={styles.formationDates}>{formation.date}</div>
            <div className={styles.formationSpeciality}>
              {formation.description}
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default Formation
