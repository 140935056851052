import * as React from 'react'

import { Menu } from 'components/Menu/Menu'
import Socials from 'components/Socials/Socials'
import styles from './style.module.scss'
import { useNavigate } from 'react-router-dom'

const Header = () => {
  const navigate = useNavigate()

  return (
    <div className={styles.headerContainer}>
      <div className={styles.header}>
        <div>
          <div className={styles.title} onClick={() => navigate('/')}>
            <h1 className={styles.h1}>Clément BABY</h1>
            <h2 className={styles.h2}>Fullstack Developer</h2>
          </div>
        </div>
        <div className={styles.icons}>
          <Socials isVertical={true} />
        </div>
      </div>
      <Menu />
    </div>
  )
}

export default Header
