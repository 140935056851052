import * as React from 'react'

import { ExperiencesData } from 'domain/interface/experiencesData'
import { IconName } from 'domain/enum'
import TechnoIcon from 'components/TechnoIcon/TechnoIcon'
import styles from './style.module.scss'

type Props = {
  activity: ExperiencesData['acitivities'][0]
}

const Activity = (props: Props) => {
  const { activity } = props

  return (
    <div className={styles.activityContent}>
      <div className={styles.jobHeader}>
        <h4 className={styles.h4}>
          {activity.job}
          <div className={styles.line}></div>
        </h4>
        <div className={styles.technoIcons}>
          {activity.technologies.map(icon => (
            <TechnoIcon key={icon} iconName={icon as IconName} />
          ))}
        </div>
      </div>
      <div className={styles.jobHeader}>
        <h5 className={styles.h5}>{activity.date}</h5>
      </div>
      <div className={styles.jobDescription}>{activity.description}</div>
      <ul className={styles.ul}>
        {activity.details.map((description, index) => (
          <li key={index}>{description}</li>
        ))}
      </ul>
    </div>
  )
}

export default Activity
