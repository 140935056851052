import Footer from 'components/Footer/Footer'
import Header from 'components/Header/Header'
import React from 'react'
import styles from './style.module.scss'

interface Props {
  children: React.ReactNode
}

export const ContentPage = (props: Props) => {
  return (
    <div className={styles.container}>
      <Header />
      {props.children}
      <Footer />
    </div>
  )
}
