import { IconName } from 'domain/enum'
import React from 'react'
import { SiKicad } from 'react-icons/si'
import { Tooltip } from '@mui/material'
import { blockMissingSwitchCase } from 'helpers'
import styles from './style.module.scss'

interface Props {
  iconName: IconName
}

const TechnoIcon = (props: Props) => {
  const { iconName } = props

  switch (iconName) {
    case IconName.Typescript:
      return (
        <Tooltip title="Typescript" className={styles.icon}>
          <img
            src="/assets/icons/typescript.svg"
            alt="typescript"
            className={styles.img}
          />
        </Tooltip>
      )
    case IconName.Javascript:
      return (
        <Tooltip title="Javascript" className={styles.icon}>
          <img
            src="/assets/icons/javascript.svg"
            alt="javascript"
            className={styles.img}
          />
        </Tooltip>
      )
    case IconName.Python:
      return (
        <Tooltip title="Python" className={styles.icon}>
          <img
            src="/assets/icons/python.svg"
            alt="python"
            className={styles.img}
          />
        </Tooltip>
      )
    case IconName.GraphQl:
      return (
        <Tooltip title="Graphql" className={styles.icon}>
          <img
            src="/assets/icons/graphql.svg"
            alt="graphql"
            className={styles.img}
          />
        </Tooltip>
      )
    case IconName.PostgreSQL:
      return (
        <Tooltip title="PostgreSQL" className={styles.icon}>
          <img
            src="/assets/icons/postgresql.svg"
            alt="postgresql"
            className={styles.img}
          />
        </Tooltip>
      )
    case IconName.Aws:
      return (
        <Tooltip title="Outils AWS" className={styles.icon}>
          <img
            src="/assets/icons/aws.svg"
            alt="aws"
            width="24"
            height="24"
            className={styles.img}
          />
        </Tooltip>
      )
    case IconName.OpenCv:
      return (
        <Tooltip title="OpenCV" className={styles.icon}>
          <img
            src="/assets/icons/opencv.svg"
            alt="opencv"
            className={styles.img}
          />
        </Tooltip>
      )
    case IconName.Tensorflow:
      return (
        <Tooltip title="TensorFlow" className={styles.icon}>
          <img
            src="/assets/icons/tensorflow.svg"
            alt="tensorflow"
            className={styles.img}
          />
        </Tooltip>
      )
    case IconName.React:
      return (
        <Tooltip title="React" className={styles.icon}>
          <img
            src="/assets/icons/react.svg"
            alt="react"
            className={styles.img}
          />
        </Tooltip>
      )
    case IconName.Nodejs:
      return (
        <Tooltip title="Node JS" className={styles.icon}>
          <img
            src="/assets/icons/nodejs.svg"
            alt="nodejs"
            className={styles.img}
          />
        </Tooltip>
      )
    case IconName.Vitest:
      return (
        <Tooltip title="Vitest" className={styles.icon}>
          <img
            src="/assets/icons/vitest.svg"
            alt="vitest"
            className={styles.img}
          />
        </Tooltip>
      )
    case IconName.Jest:
      return (
        <Tooltip title="Jest" className={styles.icon}>
          <img
            src="/assets/icons/jest.svg"
            alt="jest"
            width="24"
            height="24"
            className={styles.img}
          />
        </Tooltip>
      )
    case IconName.Cplusplus:
      return (
        <Tooltip title="C++" className={styles.icon}>
          <img
            src="/assets/icons/cplusplus.svg"
            alt="cplusplus"
            className={styles.img}
          />
        </Tooltip>
      )
    case IconName.Arduino:
      return (
        <Tooltip title="Arduino" className={styles.icon}>
          <img
            src="/assets/icons/arduino.svg"
            alt="arduino"
            className={styles.img}
          />
        </Tooltip>
      )
    case IconName.Kicad:
      return (
        <Tooltip title="KiCad" className={styles.icon}>
          <div>
            <SiKicad size={24} color="#314cb0" className={styles.img} />
          </div>
        </Tooltip>
      )
    case IconName.Cucumber:
      return (
        <Tooltip title="Cucumber" className={styles.icon}>
          <img
            src="/assets/icons/cucumber.svg"
            alt="cucumber"
            className={styles.img}
          />
        </Tooltip>
      )
    case IconName.Docker:
      return (
        <Tooltip title="Docker" className={styles.icon}>
          <img
            src="/assets/icons/docker.svg"
            alt="docker"
            className={styles.img}
          />
        </Tooltip>
      )
    case IconName.Notion:
      return (
        <Tooltip title="Notion" className={styles.icon}>
          <img
            src="/assets/icons/notion.svg"
            alt="notion"
            className={styles.img}
          />
        </Tooltip>
      )
    case IconName.Slack:
      return (
        <Tooltip title="Slack" className={styles.icon}>
          <img
            src="/assets/icons/slack.svg"
            alt="slack"
            className={styles.img}
          />
        </Tooltip>
      )
    case IconName.Github:
      return (
        <Tooltip title="Github" className={styles.icon}>
          <img
            src="/assets/icons/github.svg"
            alt="github"
            className={styles.img}
          />
        </Tooltip>
      )
    default:
      return blockMissingSwitchCase(iconName)
  }
}

export default TechnoIcon
