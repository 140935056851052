import Activity from 'components/Activity/Activity'
import React from 'react'
import data from 'assets/experiences.json'
import styles from './style.module.scss'

export const Experiences = () => {
  const { experiences } = data

  return (
    <>
      {experiences.map(experience => (
        <div key={experience.id} className={styles.jobContent}>
          <img
            className={styles.img}
            alt={experience.image}
            src={experience.image}
          />
          <h3 className={styles.h3}>{experience.seniority}</h3>
          {experience.activities.map(activity => (
            <Activity key={activity.job} activity={activity} />
          ))}
        </div>
      ))}
    </>
  )
}
