import React from 'react'
import Socials from '../Socials/Socials'
import styles from './style.module.scss'

const Footer: React.FC = () => {
  return (
    <div className={styles.footer}>
      <Socials />
      <div>© Clément Baby Copyright 2024</div>
    </div>
  )
}

export default Footer
